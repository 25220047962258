<template>
  <div class="checkout-cart-body">
    <div v-if="!loading">
      <b-card no-body class="p-0">
        <b-container fluid>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <slot name="cart-header" />
            <div>
              <em v-if="cartEmpty">Your cart is empty.</em>
              <div v-else class="cart-items">
                <cart-item-list :dashed="true" />
              </div>
            </div>
          </b-card-header>
        </b-container>
      </b-card>

      <b-card v-if="total" no-body class="cart-total">
        <div class="d-flex flex-row justify-content-between pr-3 pl-3">
          <span>Payment</span>
          <b>{{ cartItemsTotal | dollars }}</b>
        </div>
      </b-card>

      <b-card no-body>
        <select-payment-method
          :is-billing-active="isBillingActive"
          :card-header-text="selectPaymentMethodCardHeaderText"
          :total="cartItemsTotal"
          @missingPaymentMethod="missingPaymentMethod"
          @closeSection="closeSection"
          @togglePanel="togglePanel"
          @add-method-result="addMethodResult"
        />
      </b-card>

      <checkout-cart-submit-payment
        v-if="showSubmitRequest"
        :verify-order-checkout="verifyOrderCheckout"
        :alternate-checkout-button-label="alternateCheckoutButtonLabel"
        @submit-vto="$emit('checkout-completed')"
        @verify-and-checkout="verifyAccountInfoAndCheckout"
      />
    </div>
    <div v-else class="text-center p-3">
      <h4>
        Processing Order
      </h4>
      <ct-centered-spinner />
    </div>
    <collect-account-info
      :ref="`collectAccountInfoModal-${uid}`"
      @success="completeCheckout"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { dollars } from '@/filters'
import { SelectPaymentMethod } from '@/components/shared/PaymentModals/index.js'

export default {
  name: 'CheckoutCartBody',
  components: {
    CheckoutCartSubmitPayment: () => import('@/components/shared/CheckoutCartSubmitPayment.vue'),
    CollectAccountInfo:        () => import('@/components/CollectAccountInfo'),
    CtCenteredSpinner:         () => import('@/components/shared/CtCenteredSpinner'),
    CartItemList:              () => import('@/components/Checkout/CartItemList'),
    SelectPaymentMethod,
  },
  filters: {
    dollars,
  },
  props: {
    bus: {
      type: Object,
      default: null,
    },
    dashed: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Boolean,
      default: false,
    },
    selectPaymentMethodCardHeaderText: {
      type: String,
      default: 'Payment',
    },
    alternateCheckoutButtonLabel: {
      type: String,
      default: '',
    },
    verifyOrderCheckout: {
      type: Boolean,
      default: false,
    },
    verifyOrderCheckoutButtonOverrides: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      isBillingActive: false,
      showSubmitRequest: true,
      tosUrl: null,
      result: null,
      origin: 'stageline',
      uid: this._uid,
    }
  },
  computed: {
    ...mapGetters('account', [
      'missingContactInformation',
    ]),
    ...mapGetters('checkout', [
      'productItems',
      'promoCodes',
      'cartItemsTotal',
      'cartEmpty',
      'selectedPaymentMethod',
      'processingCheckout',
    ]),
    ...mapGetters('companies', ['currentCompany']),
    ...mapGetters('website', ['website']),
    missingPaymentMethod() {
      return (this.selectedPaymentMethod == null || this.selectedPaymentMethod.id === 'ADD')
    },
    invoiceHasItemsRequiringAttention() {
      //TODO: Need to add subscription here....
      return (this.result?.order && this.result?.order.order_items.some(oi => oi.status === 'awaiting-client-input'))
        || (this.result?.services && this.result?.services.some(s => s.status === 'awaiting-client-input'))
    },
    invoiceHasRegisterACompanyWithOrderVerificationRequired() {
      if (!this.result?.order?.order_items) return false

      return this.result?.order.order_items.some(oi => {
        return oi.status === 'order-verification-required' &&
          oi.name.toLowerCase().includes('register a company')
      })
    },
  },
  async mounted() {
    while (this.currentCompany == null) {
      await new Promise(r => setTimeout(r, 200))
    }
    this.resetPaymentMethod()
    this.loading = false
  },
  methods: {
    ...mapActions('checkout', [
      'removeFromCart',
      'checkout',
    ]),
    ...mapActions('companies', ['loadActiveItems']),
    ...mapActions('paymentMethods', ['resetPaymentMethod']),
    ...mapActions('vouchers', ['fetchCompanyVouchers']),
    closeSection() {
      this.isBillingActive = false
      this.showSubmitRequest = true
    },
    togglePanel() {
      this.isBillingActive = true
      this.showSubmitRequest = false
    },
    async verifyAccountInfoAndCheckout() {
      if (this.missingContactInformation) {
        const ref = `collectAccountInfoModal-${this.uid}`
        this.$refs[ref].show()
      } else {
        // If missingContactInfo is true, then this gets handled by the success
        // event that is emitted from the CollectAccountInfo modal
        await this.completeCheckout()
      }
    },
    invoiceContainsProductsWithVouchers(invoice) {
      return invoice?.invoice_line_items.some(i => i.product?.use_voucher) || false
    },
    addMethodResult(errors) {
      this.$emit('result-banner', errors)
    },
    async completeCheckout() {
      if (this.verifyOrderCheckout) {
        this.$emit('complete-checkout')
        return
      }

      if (this.cartEmpty) return
      this.loading = true
      this.result = await this.checkout(this)

      if (this.result.invoice) {
        // TODO Might tranche this out to set state at more granularity, just trying to get
        // something working for now. Really we should set it in the checkout store where we add
        // any service, order items, or subscriptions to the company store active *
        await this.loadActiveItems({ id: this.result.invoice.company_id })

        if (this.invoiceContainsProductsWithVouchers(this.result.invoice)) {
          await this.fetchCompanyVouchers(this.result.invoice.company_id)
        }

        if (this.invoiceHasItemsRequiringAttention) {
          this.$emit('invoiceHasItemsRequiringAttention', this.result.invoice)
        }

        if (this.invoiceHasRegisterACompanyWithOrderVerificationRequired) {
          this.bus.$emit('register-company-order-verification')
        }

        this.resetPaymentMethod()
        this.loading = false

        this.$bvToast.toast('Order has been completed!', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })

        this.$emit('checkout-completed')
      } else {
        this.loading = false
        this.$emit('result-banner', this.result.errors)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.checkout-cart-body {
  min-height: 33vh;

  .panel_active {
    display: none;
  }
  .row {
    margin-bottom: 0.25rem !important;
  }
  .card {
    padding-top: 15px;
    margin: 0 !important;
  }
  .card-header {
    background-color: white;
    border: none;
    margin: 5px 0 0 0 !important;
  }
  .cart-total {
    background-color: rgba(0,0,0,0.06);
    padding: 0.75rem 3.25rem 0.75rem 1.2rem;
    font-size: 1.125em;
    border-top: none;
    border-bottom: none;
    border-color: rgba(0,0,0,0.06);
  }
  .nav-cart-button {
    pointer-events: auto;
  }
  .container {
    text-align: left;
  }
  hr {
    margin: 0.5rem 0 0.5rem 0;
  }
  .cart-items {
    padding: 1em 2em;
  }
}
</style>
